import { render, staticRenderFns } from "./ForgotPassword.vue?vue&type=template&id=65b92d51&scoped=true"
import script from "./ForgotPassword.vue?vue&type=script&lang=js"
export * from "./ForgotPassword.vue?vue&type=script&lang=js"
import style0 from "../../assets/less/custom/pages.less?vue&type=style&index=0&prod&lang=less&external"
import style1 from "../../assets/less/custom/auth.less?vue&type=style&index=1&id=65b92d51&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65b92d51",
  null
  
)

export default component.exports